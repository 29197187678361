 <template>
  <v-container fluid class="pa-0 white">
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.upcomingMeeting") }}</b>

        <meetingForm
          ref="meetingForm"
          :selectedDay="
            $refs.meetingsCalendar && $refs.meetingsCalendar.selectedDay
          "
          @cleanNewEvent="cleanNewEvent"
        />
      </template>
    </headerToolbar>

    <MeetingsCalendar
      ref="meetingsCalendar"
      @eventCreation="addEventFromCalendar"
      @openMeetingForm="$refs.meetingForm.dialog = true"
    />

    <useMeetingAsTemplate
      :meeting="useMeetingAsTemplate_meeting"
      :display="useMeetingAsTemplate_dialog"
      @close="closeUseMeetingAsTemplateDialog"
      :redirectToTemplatesIndex="true"
    />
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import userAvatar from "../shared/userAvatar";
import date from "@/mixins/date";
import useMeetingAsTemplate from "../templates/useMeetingAsTemplate";
import { mapGetters, mapActions } from "vuex";
import meetingForm from "@/components/meetings/meetingForm";
import MeetingsCalendar from "../shared/MeetingsCalendar.vue";
import moment from "moment-timezone";
export default {
  name: "meetings_index",
  mixins: [date],
  components: {
    headerToolbar,
    userAvatar,
    useMeetingAsTemplate,
    meetingForm,
    MeetingsCalendar,
  },
  data() {
    return {
      moment,
      selectedMeetingView: "list",
      meetingsData: [],
      loadingMeetingsQuery: true,
      useMeetingAsTemplate_dialog: false,
      useMeetingAsTemplate_meeting: null,
      dateSelector: {
        view: "month",
        selectedValue: new Date().getMonth(),
      },
    };
  },
  computed: {
    ...mapGetters([
      "selectedOrganisationId",
      "currentUser",
      "startDate",
      "endDate",
      "meetings",
      "virtual_meetings",
    ]),
    meetingFilter() {
      let filter = `organisation_id == '${this.selectedOrganisationId}'`;
      filter += ` && starts_at > '${this.startDate.toISOString()}'`;
      return (filter += ` && starts_at < '${this.endDate.toISOString()}'`);
    },
  },
  methods: {
    ...mapActions(["getMeetings", "removeMeeting", "setEditedMeeting"]),
    addEventFromCalendar(event) {
      // calculate total duration
      var startTime = this.moment(event.start);
      var endTime = this.moment(event.end);
      var duration = this.moment.duration(endTime.diff(startTime));
      this.setEditedMeeting({
        id: "",
        title: event.name,
        timed_topics: false,
        starts_at: event.start,
        duration: parseInt(duration.asMinutes()),
        user_ids: [this.currentUser.id],
      });
      this.$refs.meetingForm.dialog = true;
    },
    useAsTemplate(meeting) {
      this.useMeetingAsTemplate_dialog = true;
      this.useMeetingAsTemplate_meeting = meeting;
    },
    cleanNewEvent() {
      // console.log("Clean new event emit")
      this.$refs.meetingsCalendar.createEvent = null;
    },
    closeUseMeetingAsTemplateDialog() {
      this.useMeetingAsTemplate_dialog = false;
      this.useMeetingAsTemplate_meeting = null;
    },
    load(scrollToNext) {
      if (!this.currentUser) return;

      this.loadingMeetingsQuery = true;
      this.getMeetings(this.meetingFilter)
        .then(() => {
          this.loadingMeetingsQuery = false;
          if (scrollToNext) {
            this.scrollToNextMeeting(0);
          }
        })
        .catch((error) => {
          this.loadingMeetingsQuery = false;
        });
    },
  },
  mounted() {
    document.title = this.$t("navigationMenu.upcomingMeeting");
    if (this.selectedOrganisationId) this.load();
    this.$amplitude.getInstance().logEvent("Page: Meetings");
  },
  watch: {
    selectedOrganisationId(val) {
      if (val) this.load();
    },
    currentUser(val) {
      if (val) this.load();
    },
    startDate() {
      this.load();
    },
    endDate() {
      this.load();
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped>
</style>

<style>
.theme--light.v-application {
  background-color: white !important;
}
</style>
