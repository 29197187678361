<template>
  <v-dialog content-class="rounded-lg" v-model="dialog" width="500">
    <v-card class="rounded-lg">
      <v-toolbar flat dense color="error" dark>
        <v-icon size="20">mdi-shield-alert</v-icon>

        <v-toolbar-title class="pl-2 subtitle-1">
          {{ $t("meetings.deleteRecurrence") }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4 body-2" v-if="!loading">
        <p v-html="message"></p>

        <v-radio-group v-model="selectedAction"   hide-details>
          <v-radio
            class="mb-2"
            v-for="(action, index) in actions"
            :key="index"
            :label="action.label"
            :value="action.value"

          ></v-radio>
        </v-radio-group>


      </v-card-text>

      <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
        <v-row wrap no-gutters>
          <v-col class="text-xs-center d-flex justify-center" cols="12"
            ><v-progress-circular
              color="error"
              indeterminate
              size="48"
              width="4"
            ></v-progress-circular
          ></v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              {{ $t("misc.deleting") }}
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" small depressed @click="close()">
          {{ $t("misc.no") }}
        </v-btn>
        <v-btn
          class="white--text"
          :disabled="loading"
          small
          color="error"
          depressed
          @click="destroy()"
        >
          <v-icon small class="pr-2">mdi-shield-alert</v-icon
          >{{ $t("misc.yes") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from "moment-timezone";
import * as api from '@/services/no-meetings.api'
import CANCEL_ON_DAY_RECURRENT_MEETING from "@/graphql/recurrent_meetings/cancel_on_day.gql";
import TRUNCATE_RECURRENT_MEETING from "@/graphql/recurrent_meetings/truncate.gql";

export default {
  name: "recurringMeetingDestroyDialog",
  mixins: [],
  components: {},
  props: {
    meeting: {
      type: Object,
      required: false,
      default: (() => {})
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      actions: [
        {
          label: this.$t("meetings.deleteRecurrenceOnlyOne"),
          value: "one",
        },
        {
          label: this.$t("meetings.deleteRecurrenceOneAndNext"),
          value: "all_since",
        },
        {
          label: this.$t("meetings.deleteRecurrenceOnlyAll"),
          value: "all",
        },
      ],
      selectedAction: "one",
    };
  },
  computed: {
    ...mapGetters(['recurrent_meetings_set', 'organisation_recurrent_meetings']),
    message() {
      if (!this.meeting) return ''
      return this.$t("meetings.removeAlertMessage").replace("<meeting_title>",this.meeting.title);
    }
  },
  methods: {
    ...mapActions([
      "setSubField",
      "refreshModel",
      "setInstance",
      "removeInstance",
      "getMeetings"
    ]),
    close() {
      this.selectedChoice = 0;
      this.dialog = false;
    },
    destroy() {
      if (this.selectedAction === 'one')
        this.addExceptionToSerie();
      else if (this.selectedAction === 'all_since')
        this.removeSerieSince();
      else
        this.removeAllSerie();
    },
    addExceptionToSerie() {
      console.log(this.meeting)
      /*
        Set meeting day as exception day in the serie,
        if an instanciated meeting exists this day -> remove it
        rehydrate
      */
      if (this.loading) return;

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CANCEL_ON_DAY_RECURRENT_MEETING,
          variables: {
            id: this.meeting.recurrent_meeting_id,
            day_to_cancel: this.meeting.starts_at,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          let rec = this.recurrent_meetings_set[this.meeting.recurrent_meeting_id];
          if (rec) {
            let exceptions = JSON.parse(rec.canceled_on_days || '[]')
            exceptions.push(this.meeting.starts_at)
            this.setSubField({
              model: "recurrent_meetings",
              id: this.meeting.recurrent_meeting_id,
              field: 'canceled_on_days',
              value: JSON.stringify(exceptions),
            });
            this.refreshModel("recurrent_meetings");
          }
          this.dialog = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    removeSerieSince() {
      /*
        Set end of the serie to meeting day - 1days
        Remove next associated series
        Remove instanciated meeting if they are over this period
      */
      if (this.loading) return;
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: TRUNCATE_RECURRENT_MEETING,
          variables: {
            id:  this.meeting.recurrent_meeting_id,
            ends_at: moment(this.meeting.starts_at).subtract(1, 'days').format(),
          },
        })
        .then(({ data }) => {
          this.dialog = false;
          this.loading = false;
          let ret = data.truncate_recurrent_meeting;
          let father_id = ret.recurrent_meeting_root_id || ret.id;
          this.organisation_recurrent_meetings.filter(
              (e) => e.recurrent_meeting_root_id === father_id && moment(e.starts_at) >= moment(ret.ends_at)
            )
            .forEach((e) => {
              this.removeInstance({
                model: "recurrent_meeting",
                instance_id: e.id,
              });
            });
          this.setInstance({
            model: "recurrent_meeting",
            instance: data.truncate_recurrent_meeting,
          });
          this.getMeetings();
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    async removeAllSerie() {
      if (this.loading) return;
      this.loading = true;
      /* Remove the parent serie, all there childs, all related isntanciated meetings */
      let objid = this.meeting.recurrent_meeting.recurrent_meeting_root_id || this.meeting.recurrent_meeting.id
      const { data } = await api.destroy(objid, 'recurrent_meeting');
      let associated_rec = this.organisation_recurrent_meetings.filter(
          (e) => e.id === objid || e.recurrent_meeting_root_id === objid
      )
      associated_rec.forEach((e) => {
          this.removeInstance({
            model: "recurrent_meeting",
            instance_id: e.id,
          });
        });
      this.refreshModel("recurrent_meetings");
      this.getMeetings();
      this.loading = false;
      this.dialog = false;
    },
  },
  watch: {},
};
</script>
