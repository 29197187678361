<template>
  <v-dialog :value="display" persistent max-width="480" transition="dialog-top-transition">
    <v-card>
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ $t("templates.saveAsTemplate") }}</span>
        <v-btn icon :disabled="loading" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5" v-if="!loading">
        <v-text-field
          v-model="templateName"
          :label="$t('templates.templateName')"
          placeholder=""
          filled
          dense
          autocomplete="off"
        />
      </v-card-text>
      <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
        <v-row wrap no-gutters>
          <v-col class="text-xs-center d-flex justify-center" cols="12"
            ><v-progress-circular color="button_blue" indeterminate size="48" width="4"></v-progress-circular></v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              {{ $t("templates.creatingTemplate") }}
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :disabled="loading" text @click="$emit('close')" color="almost_black">
          {{ $t("misc.cancel") }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="white--text"
          depressed
          :disabled="!templateName || loading"
          @click="createTemplate()"
        >
          {{ $t("templates.createTemplate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CREATE_MEETING_TEMPLATE from "@/graphql/meeting_templates/create.gql";
import { mapGetters } from 'vuex';
export default {
  name: "useMeetingAsTemplate",
  mixins: [],
  components: {},
  data() {
    return {
      templateName: "",
      loading: false,
    };
  },
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    meeting: {
      type: Object,
    },
    redirectToTemplatesIndex: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters(['selectedOrganisationId'])
  },
  methods: {
    close() {},
    createTemplate() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_MEETING_TEMPLATE,
          variables: {
            meeting_id: this.meeting.id,
            name: this.templateName,
            organisation_id: this.selectedOrganisationId
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.sendSuccess(this.$t("templates.createSuccess"));
          this.$emit('close')
          if (this.redirectToTemplatesIndex)
          this.$router.push({ name: "templates_index" });
        })
        .catch((error) => {
          this.sendError(error);
          this.loading = false;
        });
    },
  },
  mounted() {},
  beforeDestroy() {},
  watch: {
    display() {
      if (this.display == true && this.meeting) {
        this.templateName = this.meeting.title;
      }
    },
  },
};
</script>

