<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :ripple="false"

      height="42px"
      class="d-flex align-center"
      style="cursor: pointer"
      :color="hoverColor(hover)"
      @click="goToMeeting()"
      flat
      :dark="hover"
    >
      <!-- Is Next meeting -->
      <div v-if="isNextMeeting" class="nextMeeting" />

      <!--Blinking  Live if paused or started-->
      <!-- <div
        v-if="meeting.status == 'started' || meeting.status == 'paused'"
        class="meetingItemStatusChip blinkElement"
      >
        <v-icon size="12" class="pr-1 pt-1" color="error"
          >mdi-checkbox-blank-circle</v-icon
        ><span class="meetingLiveLabel">{{
          $t("meetings.meetingIsLive")
        }}</span>
      </div> -->

      <div style="flex-shrink: 0" class="d-flex align-center">
        <div style="width: 150px; height: 100%; vertical-align: center" class="d-flex align-center">
          <v-icon size="15" class="pl-3 pr-2 pb-0">mdi-clock-outline</v-icon>
          <span class="body-3 font-weight-bold">{{
            hoursMinutes(new Date(meeting.starts_at.replace(/-/g, "/")))
          }}</span>
          <v-icon size="12" class="pl-2">mdi-arrow-right-bold</v-icon>
          <span class="pr-4 pl-2" style="font-size: 13px">{{
            meeting.duration
              ? addMinutesToTime(new Date(meeting.starts_at), meeting.duration)
              : "--:--"
          }}</span>
        </div>
        <div style="width: 116px" class="hidden-sm-and-down">
          <!-- <meetingStatusChip
            v-if="
              meeting.status == 'finished' ||
              meeting.status == 'cancel' ||
              meeting.status == 'archived'
            "
            :meetingStatus="meeting.status"
          /> -->
          <v-chip :disabled="!meeting.duration" color="light_grey" light small>
            <v-icon size="12" class="pr-1">mdi-timer</v-icon>
            {{
              meeting.duration
                ? meeting.duration + " " + $t("misc.timer.minutes")
                : "---"
            }}
          </v-chip>
        </div>
      </div>

      <div style="flex: 1">
        <b style="font-size: 0.8em"> {{ meeting.title }}</b>
      </div>

      <!--<div style="flex-shrink: 1; width: 146px" class="hidden-md-and-down mr-4">
         <userAvatarGroup
          :limit="3"
          :users="meeting.meeting_users.map((mu) => mu.user)"
          :size="24"
          :avatar_border="!hover"
        />
      </div> -->

      <div style="width: 42px; flex-shrink: 0" class="">
        <!-- Action Menu -->
        <v-menu bottom left v-model="showMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list dense>
              <!-- HIDE THOSE FUNCTIONS FOR NOW  -->
              <!-- <v-list-item disabled @click="editMeetingInfos()">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-playlist-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("meetings.editMeetingInfos")
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item>

              <v-list-item disabled @click="duplicateMeeting()">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-content-duplicate</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("misc.duplicate")
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item>

              <v-list-item disabled @click="archiveMeeting()">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-archive</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("misc.archive")
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item> -->

              <!-- <v-list-item @click="useAsTemplate()">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-content-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("templates.saveAsTemplate")
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item> -->

                <v-list-item @click="$emit('editMeeting', meeting)">
                <v-list-item-icon class="mr-1">
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("meetings.editMeeting")
                  }}</v-list-item-title></v-list-item-content
                >
              </v-list-item>



              <v-list-item @click="$emit('removeMeeting', meeting)">
                <v-list-item-icon class="mr-1">
                  <v-icon color="error" small>mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="error--text">{{
                    $t("misc.delete")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>

    </v-card>

  </v-hover>
</template>

<script>
import { mapActions } from "vuex";
import userAvatarGroup from "../shared/userAvatarGroup";
import date from "@/mixins/date";
import meetingStatusChip from "./meetingStatusChip";

export default {
  name: "MeetingItem",
  mixins: [date],
  components: { userAvatarGroup, meetingStatusChip },
  props: {
    meeting: {
      type: Object,
    },
    isNextMeeting: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return { showMenu: false, x: 0, y: 0 };
  },
  computed: {},
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
     editMeetingInfos() {
      console.log("Click on editMeetingInfos");
    },
    duplicateMeeting() {
      console.log("Click on duplicateMeeting");
    },
    archiveMeeting() {
      console.log("Click on archiveMeeting");
    },

    useAsTemplate() {
      this.$emit("useAsTemplate", this.meeting);
    },
    goToMeeting() {
       this.$emit("goToMeeting", this.meeting);
    },
    hoverColor(hover) {
      if (hover) {
        return this.isNextMeeting ? "next_meeting" : "button_blue";
      }

      if (
        this.meeting.status == "finished" ||
        this.meeting.status == "cancel" ||
        this.meeting.status == "archived"
      )
        return "#ffffff";
      return "white";
    },
  },
  mounted() {},
};
</script>

<style >
.truncatedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nextMeeting {
  /* border-left: 8px solid var(--v-next_meeting); */
  border: 3px solid var(--v-next_meeting) !important;
  border-radius: 4px !important;
  width: 0px;
  height: 68px;
  position: absolute;
  left: -14px;
  top: 0px;
}

.transparentMeeting {
  opacity: 0.5;
}
</style>



