<template>
  <v-chip
    class="py-1 px-2"
    :class="status[meetingStatus].class"
    label
    style="height: 18px"
  >
    <v-icon
      :color="status[meetingStatus].iconColor"
      class="pr-1"
      :size="status[meetingStatus].iconSize"
    >
      {{ status[meetingStatus].icon }}</v-icon
    >
    <span style="font-size: 0.8em; line-height: 0.1em">{{ status[meetingStatus].text }}</span>
  </v-chip>
</template>

<script>
export default {
  name: "meetingStatusChip",
  data() {
    return {
      status: {
        pending: {
          iconColor: "white",
          icon: "mdi-calendar-clock",
          iconSize: "12",
          class: "button_blue white--text",
          text: this.$t("meetings.status.pending"),
        },
        canceled: {
          iconColor: "white",
          icon: "mdi-close",
          iconSize: "12",
          class: "almost_black white--text",
          text: this.$t("meetings.status.canceled"),
        },
        started: {
          iconColor: "white",
          icon: "mdi-checkbox-blank-circle",
          iconSize: "10",
          class: "error white--text",
          text: this.$t("meetings.status.started"),
        },
        paused: {
          iconColor: "white",
          icon: "mdi-pause",
          iconSize: "12",
          class: "amber darken-3 white--text",
          text: this.$t("meetings.status.paused"),
        },
        finished: {
          iconColor: "white",
          icon: "mdi-check",
          iconSize: "12",
          class: "success white--text",
          text: this.$t("meetings.status.finished"),
        },
        archived: {
          iconColor: "white",
          icon: "mdi-archive",
          iconSize: "12",
          class: "dark_grey white--text",
          text: this.$t("meetings.status.archived"),
        },
      },
    };
  },
  props: {
    meetingStatus: {
      type: String,
      required: true,
    },
  },

  methods: {},
};
</script>
