 <template>
  <div>
    <v-divider></v-divider>
    <v-container fluid class="pa-0">
      <v-row wrap no-gutters>
        <v-col cols="auto" md="auto">
          <v-row wrap no-gutters>
            <v-date-picker
              no-title
              :value="simpleDate(selectedDay)"
              @change="updateSelectedDay"
              first-day-of-week="1"
              :locale="$i18n.locale"
              :events="calendarEvents.map((ev) => simpleDate(ev.start))"
              event-color="button_blue"
              color="calendarToday"
              show-week
            ></v-date-picker>
          </v-row>
        </v-col>
        <v-col style="height: calc(100vh - 148px)">
          <v-toolbar flat dense>
            <v-row wrap no-gutters align="center">
              <!-- TODAY BUTTON -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    outlined
                    depressed
                    @click="goToToday()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("misc.today") }}
                  </v-btn>
                </template>
                <span> {{ fullDateAsText(dateNow()) }}</span>
              </v-tooltip>

              <!-- PREVIOUS -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4"
                    small
                    outlined
                    depressed
                    @click="previous()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20" color="mid_grey darken-4">
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </template>
                <span> {{ view_types[view_type].previous_text }}</span>
              </v-tooltip>

              <!-- NEXT -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-4 mr-8"
                    small
                    outlined
                    depressed
                    @click="next()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="20" color="mid_grey darken-4">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </template>
                <span> {{ view_types[view_type].next_text }}</span>
              </v-tooltip>

              <span class="font-weight-medium text-capitalize">{{
                displayedDate
              }}</span>
              <v-chip
                class="ml-4"
                small
                label
                color="light_grey"
                v-if="
                  selectedViewLevel == 'week' || selectedViewLevel == '4day'
                "
                >{{ $t("calendar.week") + " " + displayedWeekNumber }}</v-chip
              >

              <v-spacer></v-spacer>

              <v-select
                v-model="view_type"
                solo
                flat
                hide-details
                outlined
                dense
                :items="view_types"
                item-text="text"
                item-value="index"
                class="mx-4 mr-2 reducedVselect caption"
                style="max-width: 120px"
                @change="changeViewType($event)"
              >
              </v-select>
            </v-row>
          </v-toolbar>

          <template v-if="selectedViewLevel != 'planning'">
            <v-calendar
              class="mx-4 preventTextSelection"
              style="width: auto"
              :hide-header="selectedViewLevel == 'day'"
              ref="calendar"
              :type="selectedViewLevel"
              :locale="$i18n.locale"
              :value="simpleDate(selectedDay)"
              :events="calendarEvents"
              @click:event="showEvent"
              @contextmenu:event="editEvent"
              @click:date="viewDay"
              :event-color="getEventColor"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              @mouseleave.native="cancelDrag"
            >
              <template v-slot:day-body="{ date, week }">
                <div
                  :class="{
                    first: date === week[0].date,
                    'v-current-time': showTodaysProgressBar,
                  }"
                  :style="{ top: nowY }"
                ></div>
              </template>

              <!-- <template v-slot:event="{ event, timed, eventSummary }">
                <div class="v-event-draggable">
                  <strong>{{event.name}}</strong>

                    <br>
                    {{event.start | onlyTime  + " - " + event.end | onlyTime }}
                </div>
                <div
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
                ></div>
              </template> -->
            </v-calendar>

            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              :nudge-right="0"
              :nudge-bottom="42"
              max-width="460px"
            >
              <v-card
                width="460px"
                flat
                tile
                class="pa-0"
                v-if="selectedEvent.meeting"
              >
                <v-toolbar
                  tile
                  :color="selectedEvent.color + ' darken-2'"
                  dense
                  flat
                  dark
                >
                  <v-toolbar-title
                    class="body-1 font-weight-medium"
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>

                  <v-btn
                    icon
                    small
                    @click="deleteMeeting(selectedEvent.meeting)"
                  >
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-btn>

                  <v-btn icon small @click="editMeeting(selectedEvent)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small>
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-list dense two-line class="pb-0">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        fullDateAsText(selectedEvent.start)
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon size="14" class="pl-0 pr-2"
                          >mdi-clock-outline</v-icon
                        >
                        <span class="body-2 font-weight-bold">{{
                          hoursMinutes(
                            new Date(
                              selectedEvent.meeting.starts_at.replace(/-/g, "/")
                            )
                          )
                        }}</span>
                        <v-icon size="12" class="pl-2"
                          >mdi-arrow-right-bold</v-icon
                        >
                        <span class="pr-4 pl-2" style="font-size: 13px">{{
                          selectedEvent.meeting.duration
                            ? addMinutesToTime(
                                new Date(selectedEvent.meeting.starts_at),
                                selectedEvent.meeting.duration
                              )
                            : "--:--"
                        }}</span>

                        <v-chip
                          :disabled="!selectedEvent.meeting.duration"
                          color="light_grey"
                          light
                          small
                        >
                          <v-icon size="12" class="pr-1">mdi-timer</v-icon>
                          {{
                            selectedEvent.meeting.duration
                              ? selectedEvent.meeting.duration +
                                " " +
                                $t("misc.timer.minutes")
                              : "---"
                          }}
                        </v-chip></v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action-text
                      v-if="selectedEvent.meeting.recurrent_meeting_id"
                    >
                      <v-tooltip left color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            small
                            dark
                            color="success"
                            >{{ $t("calendar.recurring") }}</v-chip
                          >
                        </template>
                        <span>{{
                          rruleToText(
                            selectedEvent.meeting.recurrent_meeting.recurring
                          )
                        }}</span>
                      </v-tooltip>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>

                <v-container class="px-5 pt-0 pb-4" fluid>
                  <v-subheader class="pl-0">
                    <v-icon small class="pr-2">mdi-account-multiple</v-icon
                    >{{ $t("meetings.participants") }}</v-subheader
                  >
                  <v-list
                    dense
                    avatar
                    class="py-0"
                    style="width: 100%; max-height: 400px"
                  >
                    <userItem
                      v-for="user_id in selectedEvent.meeting.user_ids"
                      :key="user_id"
                      :user="users_set[user_id]"
                      dense
                      condensed
                    >
                      <template slot="custom">
                        <v-list-item-subtitle
                          class="body-4"
                          v-if="user_id == selectedEvent.meeting.user_id"
                        >
                          {{ $t("meetings.meetingOrganizer") }}
                        </v-list-item-subtitle>
                      </template>
                    </userItem>
                  </v-list>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    small
                    text
                    color="almost_black"
                    @click="selectedOpen = false"
                  >
                    {{ $t("misc.close") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="selectedEvent.meeting_id"
                    small
                    depressed
                    class="button_blue white--text"
                    :disabled="selectedEvent.loading"
                    :loading="selectedEvent.loading"
                    @click="visitEvent(selectedEvent)"
                  >
                    {{ $t("meetings.joinMeeting") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <template v-else>
            <v-divider></v-divider>
            <v-col
              cols="12"
              class="pa-8 pt-4"
              style="height: calc(100%); overflow-y: auto; overflow-x: hidden"
              id="meetings_scroll_area"
            >
              <template v-for="year in Object.keys(meetingsSorted)">
                <v-row
                  dense
                  no-gutter
                  v-for="month in Object.keys(meetingsSorted[year])"
                  :key="month + '_' + year"
                  class="pb-2 px-0"
                >
                  <!-- Month -->

                  <v-col class="pb-4 pl-0">
                    <v-btn
                      text
                      class="text-uppercase meeting-month"
                      @click="goToMonthView(year, month)"
                      ><b class="text-uppercase">{{
                        meetingsSorted[year][month].name
                      }}</b>
                    </v-btn>
                  </v-col>

                  <!-- Day by day -->
                  <v-container
                    fluid
                    v-for="day in Object.keys(
                      meetingsSorted[year][month]['data']
                    )"
                    :key="day"
                  >
                    <v-row>
                      <!-- Day and weekday -->
                      <v-col
                        shrink
                        style="max-width: 38px"
                        align="center"
                        class="pa-0"
                      >
                        <span class="meeting-day caption">{{
                          meetingsSorted[year][month]["data"][day].name
                        }}</span>
                        <v-btn
                          small
                          fab
                          class="button"
                          :dark="
                            meetingsSorted[year][month]['data'][day].isToday
                          "
                          :color="
                            meetingsSorted[year][month]['data'][day].isToday
                              ? 'calendarToday'
                              : ''
                          "
                          depressed
                          @click="
                            goToDayView(
                              meetingsSorted[year][month]['data'][day].date
                            )
                          "
                        >
                          <span class="meeting-day">{{ day }}</span>
                        </v-btn>
                      </v-col>

                      <v-col class="pa-0 pb-2">
                        <!-- Individual Meetings -->
                        <v-row
                          wrap
                          no-gutters
                          fluid
                          v-for="meeting in meetingsSorted[year][month]['data'][
                            day
                          ]['data'].sort(sortByHourMinutesDesc)"
                          :key="meeting.id"
                          class="pl-2 ml-4 mr-8"
                        >
                          <!--  :class="{ nextMeeting: meeting.id == nextMeetingId }" -->

                          <v-col cols="12">
                            <meetingItem
                              :isNextMeeting="false"
                              :id="'meeting_' + meeting.id"
                              :meeting="meeting"
                              @removeMeeting="deleteMeeting"
                              @goToMeeting="visitMeeting"
                              @editMeeting="editMeeting"
                            />
                          </v-col>

                          <!-- v-on:destroy="removeMeeting(meeting.id)" @useAsTemplate="useAsTemplate"-->
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </template>
            </v-col>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <recurringMeetingDestroyDialog
      ref="recurringMeetingDestroyDialog"
      :meeting="meetingToDestroy"
    />
  </div>
</template>

<script>
import date from "@/mixins/date";
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import userItem from "@/components/users/userItem";
import CREATE_MEETING from "@/graphql/meetings/create.gql";
import { RRule, RRuleSet, rrulestr } from "rrule";
import meetingItem from "@/components/meetings/meetingItem";
import recurringMeetingDestroyDialog from "@/components/meetings/recurringMeetingDestroyDialog";
export default {
  name: "MeetingsCalendar",
  mixins: [date],
  components: { userItem, meetingItem, recurringMeetingDestroyDialog },
  props: {
    /*  events: {
      type: Array,
      default: () => [],
    }, */
  },
  data() {
    return {
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      selectedDay: Date.now(),
      view_type: 1,
      meetingToDestroy: null,
      view_types: [
        {
          level: "day",
          text: this.$t("calendar.day"),
          previous_text: this.$t("calendar.previousDay"),
          next_text: this.$t("calendar.nextDay"),
          index: 0,
        },
        {
          level: "week",
          text: this.$t("calendar.week"),
          previous_text: this.$t("calendar.previousWeek"),
          next_text: this.$t("calendar.nextWeek"),
          index: 1,
        },
        {
          level: "month",
          text: this.$t("calendar.month"),
          previous_text: this.$t("calendar.previousMonth"),
          next_text: this.$t("calendar.nextMonth"),
          index: 2,
        },
        {
          level: "4day",
          text: this.$t("calendar.days4"),
          previous_text: this.$t("calendar.previousPeriod"),
          next_text: this.$t("calendar.nextPeriod"),
          index: 3,
        },
        {
          level: "planning",
          text: this.$t("calendar.planning"),
          previous_text: this.$t("calendar.previousPeriod"),
          next_text: this.$t("calendar.nextPeriod"),
          index: 4,
        },
      ],
      ready: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
    };
  },
  computed: {
    ...mapGetters([
      "meetings",
      "startDate",
      "endDate",
      "virtual_meetings",
      "users_set",
    ]),
    showTodaysProgressBar() {
      if (this.selectedViewLevel == "day") {
        return moment(this.selectedDay).isSame(moment(), "day");
      } else if (this.selectedViewLevel == "week") {
        return moment(this.selectedDay).isSame(moment(), "week");
      }
    },
    meetingsSorted() {
      // grouped meetings
      var _gm = {};

      //Initialise temp date variables
      var _d;
      var _d_year;
      var _d_month;
      var _d_month_i;
      var _d_day;
      var _d_day_i;

      var _tempDate;
      this.meetings.forEach((meeting) => {
        _d = new Date(meeting.starts_at.replace(/-/g, "/"));
        _d_year = _d.getFullYear();
        _d_month =
          _d.toLocaleString(this.$i18n.locale, { month: "long" }) +
          " " +
          _d_year;
        _d_month_i = _d.getMonth();
        _d_day = _d.toLocaleString(this.$i18n.locale, { weekday: "short" });
        _d_day_i = _d.getDate();
        // Create Year group if not exist
        if (_gm.hasOwnProperty(_d_year) === false) {
          _gm[_d_year] = {};
        }

        // Create Month group if not exist
        if (_gm[_d_year].hasOwnProperty(_d_month_i) === false) {
          _gm[_d_year][_d_month_i] = {
            name: _d_month,
            data: {},
          };
        }

        // Create Day group if not exist
        if (
          _gm[_d_year][_d_month_i]["data"].hasOwnProperty(_d_day_i) === false
        ) {
          _gm[_d_year][_d_month_i]["data"][_d_day_i] = {
            name: _d_day,
            isToday: moment(_d).isSame(moment(), "day"),
            date: _d,
            data: [],
          };
        }

        _gm[_d_year][_d_month_i]["data"][_d_day_i]["data"].push(meeting);
      });
      return _gm;
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    selectedViewLevel() {
      return this.view_types[this.view_type].level;
    },
    displayedDate() {
      switch (this.selectedViewLevel) {
        case "day":
          return this.fullDateAsText(this.selectedDay);
        case "week":
        case "4day":
          return this.monthYear(this.selectedDay);
        case "month":
          return this.monthYear(this.selectedDay);
        case "planning":
          return (
            this.monthYear(this.selectedDay) +
            " - " +
            this.monthYear(moment(this.selectedDay).add(2, "month"))
          );
      }
    },
    displayedWeekNumber() {
      return this.weekNumber(this.selectedDay);
    },
    // nextMeetingId() {
    //   // sort the meetings by date and return the meeting id of closest meeting to come
    //   var _meetings = this.meetings.sort((a, b) => {
    //     return new Date(a.starts_at) - new Date(b.starts_at);
    //   });
    //   var _now = new Date();
    //   var _nextMeeting = _meetings.find((meeting) => {
    //     return new Date(meeting.starts_at) > _now;
    //   });
    //   return _nextMeeting ? _nextMeeting.id : null;

    // },
    calendarEvents() {
      var ret;
      var meetingsArray = this.meetings.map((ev) => {
        return {
          meeting_id: ev.id,
          meeting: ev,
          name: ev.title,
          start: new Date(ev.starts_at),
          timed: true,
          virtual: !!ev.virtual,
          loading: false,
          color: ev.color ? ev.color : "blue",
          end: ev.duration
            ? new Date(ev.starts_at).getTime() + ev.duration * 60000
            : new Date(ev.starts_at).getTime() + 60 * 60000,
        };
      });

      if (this.createEvent) {
        ret = meetingsArray.concat([this.createEvent]);
      } else ret = meetingsArray;
      // console.log("calendarEvents", ret, "this.createEvent", this.createEvent);
      return ret;
    },
  },
  methods: {
    ...mapActions([
      "setStartDate",
      "setEndDate",
      "setEditedMeeting",
      "removeMeeting",
      "openDestroyDialog",
      "getMeetings"
    ]),
    // hidePopUps(event) {
    //   console.log("click outside event", event)
    //   this.selectedOpen = null;
    // },
    editMeeting(event) {
      this.setEditedMeeting(event.meeting);
      this.$emit("openMeetingForm");
    },
    viewDay({ date }) {
      this.updateSelectedDay(date);
      this.view_type = 0;
    },
    visitEvent(event) {
      console.log("visitEvent", event);
      if (event.meeting && event.virtual)
        /* Visit a virtual meeting -> Create it and navigate to it */
        this.createAndJoinMeeting(event);
      else if (event.meeting)
        /* Visit a regular meeting */
        this.goToMeeting(event.meeting_id);
      else return;
      /* Handle other calendar events here */
    },
     visitMeeting(meeting) {
      console.log("visitMeeting", meeting);
      if (meeting && meeting.virtual)
        /* Visit a virtual meeting -> Create it and navigate to it */
        this.createAndJoinMeeting({meeting: meeting});
      else if (meeting)
        /* Visit a regular meeting */
        this.goToMeeting(meeting.id);
      else return;
      /* Handle other calendar events here */
    },
    createAndJoinMeeting(virtualMeeting) {
      console.log("Find or create meeting from ", virtualMeeting);
      if (virtualMeeting.loading) return;

      virtualMeeting.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_MEETING,
          variables: {
            starts_at: virtualMeeting.meeting.starts_at,
            title: virtualMeeting.meeting.title,
            duration: virtualMeeting.meeting.duration,
            timed_topics: virtualMeeting.meeting.timed_topics,
            meeting_template_id: virtualMeeting.meeting.meeting_template_id,
            recurrent_meeting_id: virtualMeeting.meeting.recurrent_meeting_id,
            organisation_id: virtualMeeting.meeting.organisation_id,
            color: virtualMeeting.meeting.color,
          },
        })
        .then(({ data }) => {
          this.goToMeeting(data.create_meeting.id);
        })
        .catch((e) => {
          virtualMeeting.loading = false;
          this.sendError(error);
        });
    },
    updateSelectedDay(date) {
      this.selectedDay = moment(date).startOf("day");
      this.updateStartEndDates();
    },
    updateStartEndDates() {
      // If selected view is planning, then set the end date to 3 months after
      if (this.selectedViewLevel === "planning") {
        this.setStartDate(moment(this.selectedDay).startOf("month"));
        this.setEndDate(
          moment(this.selectedDay).add(3, "months").endOf("month")
        );
      } else {
        //  Set start date to the previous month and end date to the next month
        this.setStartDate(moment(this.selectedDay).subtract(1, "month"));
        this.setEndDate(moment(this.selectedDay).add(1, "month"));
      }
    },
    goToToday() {
      this.updateSelectedDay(moment().startOf("day"));
    },
    goToDayView(date) {
      localStorage.setItem("CalendarView", 0);
      this.view_type = 0;
      this.updateSelectedDay(date);
    },
    goToMonthView(year, month) {
      localStorage.setItem("CalendarView", 1);
      this.view_type = 2;
      this.updateSelectedDay(moment([year, month]));
    },
    previous() {
      if (this.selectedViewLevel == "day") {
        this.updateSelectedDay(this.addDays(this.selectedDay, -1));
      } else if (this.selectedViewLevel == "week") {
        this.updateSelectedDay(this.addDays(this.selectedDay, -7));
      } else if (
        this.selectedViewLevel == "month" ||
        this.selectedViewLevel == "planning"
      ) {
        this.updateSelectedDay(this.addMonths(this.selectedDay, -1));
      } else if (this.selectedViewLevel == "4day") {
        this.updateSelectedDay(this.addDays(this.selectedDay, -4));
      }
    },
    next() {
      if (this.selectedViewLevel == "day") {
        this.updateSelectedDay(this.addDays(this.selectedDay, 1));
      } else if (this.selectedViewLevel == "week") {
        this.updateSelectedDay(this.addDays(this.selectedDay, 7));
      } else if (
        this.selectedViewLevel == "month" ||
        this.selectedViewLevel == "planning"
      ) {
        this.updateSelectedDay(this.addMonths(this.selectedDay, 1));
      } else if (this.selectedViewLevel == "4day") {
        this.updateSelectedDay(this.addDays(this.selectedDay, 4));
      }
    },
    changeViewType(event) {
      // console.log(event);
      localStorage.setItem("CalendarView", event);
      this.updateStartEndDates();
    },
    showEvent({ nativeEvent, event }) {
      // console.log(nativeEvent, event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    editEvent(event) {
      this.setEditedMeeting(event.event.meeting);
      this.$emit("openMeetingForm");
    },
    sortByHourMinutesDesc(a, b) {
      let mina = this.strToMintutesFromMidnight(a.starts_at);
      let minb = this.strToMintutesFromMidnight(b.starts_at);
      return mina < minb ? -1 : 1;
    },
    goToMeeting(id) {
      sessionStorage.setItem("CalendarSelectedDay", this.selectedDay);
      this.$router.push({
        name: "meeting_show",
        params: { id: id },
      });
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    startDrag({ event, timed }) {
      this.dragEvent = event;
      this.dragTime = null;
      this.extendOriginal = null;
      return;
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }

      console.log("START DRAG", event, timed);
    },
    startTime(tms, event) {
      if (this.selectedElement) {
        return;
      }

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: "",
          color: "mid_grey darken-1",
          start: this.createStart,
          end: this.createStart,
          timed: true,
        };
      }
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag(t) {
      if (this.selectedElement) {
        this.selectedElement = null;
        return;
      }
      let emitEvent = true;
      if (this.dragEvent && this.dragEvent.meeting_id) emitEvent = false;

      this.dragTime = null;
      this.dragEvent = null;
      this.createStart = null;
      this.extendOriginal = null;

      if (emitEvent && this.createEvent)
        this.$emit("eventCreation", this.createEvent);
      // this.createEvent = null;
    },
    cancelDrag(t) {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rruleToText(unparsed_rule) {
      var parsed = RRule.fromString(unparsed_rule);

      if (parsed) return this.capitalizeFirstLetter(parsed.toText());

      return "";
    },
    getEventColor(event) {
      return event.color;
    },
    dayIsToday(year, month, day) {
      const today = new Date();
      console.log(year, month, day);
      return (
        today.getFullYear() == year &&
        today.getMonth() + 1 == month &&
        today.getDate() == day
      );
    },
    deleteMeeting(meeting) {
      console.log("CLICK ON DELETE MEETING", meeting);

      // NON RECURRING MEETING
      if (meeting.recurrent_meeting_id == null) {
        this.openDestroyDialog({
          id: meeting.id,
          model: "meeting",
          onDestroy: this.onMeetingDestroy,
          title: this.$t("meetings.removeAlertTitle"),
          message: this.$t("meetings.removeAlertMessage").replace(
            "<meeting_title>",
            meeting.title
          ),
        });
      } else {
        // RECURRING MEETING
        this.meetingToDestroy = meeting;
        this.$refs.recurringMeetingDestroyDialog.dialog = true;
      }
    },
    onMeetingDestroy() {
      this.sendSuccess(this.$t("meetings.removeSuccessNotification"));
      this.meetingToDestroy = null;
      // WE SHOULD REHYDRATES MEETINGS HERE
      this.getMeetings();
    },
  },
  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
    if (localStorage.getItem("CalendarView")) {
      this.view_type = parseInt(localStorage.getItem("CalendarView"));
      this.changeViewType(this.view_type);
    }

    if (sessionStorage.getItem("CalendarSelectedDay")) {
      console.log(
        "SELECTED DAY",
        sessionStorage.getItem("CalendarSelectedDay")
      );

      //  this.selectedDay = parseInt(sessionStorage.getItem("CalendarSelectedDay"));
      // this.updateStartEndDates();
    }
  },
  created() {},
  watch: {},
  beforeDestroy() {},
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: var(--v-calendarToday);
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: var(--v-calendarToday);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.preventTextSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
